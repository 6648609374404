// BS
@import "node_modules/bootstrap/scss/functions";

// VARS
$accordion-body-padding-y: 2rem;
$accordion-body-padding-x: 2rem;
$accordion-bg: #fff;

$body-bg: #f2f2f2;
$link-decoration: none;
$link-color: #000;
$list-group-bg: none;
$h1-font-size: 54px;
$h2-font-size: 29px;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1170px
);

$card-border-radius: .375rem;

@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";

/// default map overrides here
$custom-colors: (
        "gold": #ccae32,
        "gold-field": #d5ad6d
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
//@import "../vendor/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
//@import "../vendor/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
//@import "../vendor/bootstrap/scss/nav";
//@import "../vendor/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/accordion";
//@import "../vendor/bootstrap/scss/breadcrumb";
//@import "../vendor/bootstrap/scss/pagination";
//@import "../vendor/bootstrap/scss/badge";
///@import "../vendor/bootstrap/scss/alert";
//@import "../vendor/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
//@import "../vendor/bootstrap/scss/toasts";
//@import "../vendor/bootstrap/scss/modal";
//@import "../vendor/bootstrap/scss/tooltip";
//@import "../vendor/bootstrap/scss/popover";
//@import "../vendor/bootstrap/scss/carousel";
//@import "../vendor/bootstrap/scss/spinners";
//@import "../vendor/bootstrap/scss/offcanvas";
//@import "../vendor/bootstrap/scss/placeholders";

// Helpers
@import "node_modules/bootstrap/scss/helpers";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack


// FONT AWSOME
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

.card {
  /*
    LINKS
    https://www.shutterstock.com/category/backgrounds-textures-sem?kw=%2Btexture%20%2Bbackground&ds_eid=700000001520949&utm_source=GOOGLE&utm_campaign=CO%3DIL_LG%3DEN_BU%3DIMG_AD%3DGENERIC_TS%3Dlggeneric_RG%3DAPAC_AB%3DACQ_CH%3DSEM_OG%3DCONV_PB%3DGoogle&ds_cid=71700000017870154&ds_ag=FF%3Dbackground-stock-all_AU%3DProspecting&ds_agid=58700002030231025&utm_medium=cpc&gclid=EAIaIQobChMImrjI0Ymg_gIVdIxoCR1h6Ao8EAAYASAAEgJtFPD_BwE&gclsrc=aw.ds
   */
  background: transparent url("../img/backgrounds/white-marble.jpg");

  .logo {
    width: 250px;
    max-height: 250px;
    border-radius: 50%;
    margin: -5% auto auto;
    padding: 0.7rem;
    box-shadow: 0 0 10px #54545480;
    object-fit: cover;
  }

  .cover {
    background: transparent url("../img/cover.jpeg") no-repeat center center / cover content-box;
    min-height: 300px;
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  .feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    border: 1px solid #CCAE32;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }
}

.divider-custom {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-custom {
  .divider-custom-line {
    width: 100%;
    max-width: 7rem;
    height: 0.25rem;
    background-color: #2c3e50;
    border-radius: 1rem;
    border-color: #2c3e50;

    &:first-child {
      margin-left: 1rem;
    }

    &:last-child {
      margin-right: 1rem;
    }
  }

}

i {
  @extend .fs-2;
  font-weight: 400;
}

// REBOOT
ul {
  padding-inline-start: 0;
}

.accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}